import { memo, useCallback } from "react"
import { Platform, ScrollView, StyleSheet, Text, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { TeamCustomQuestion } from "src/aws/API"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptionsOnNestedScreen } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useJoinOrganizationData } from "src/recoil/hooks/screens/useJoinOrganizationData"
import { useSaveLinking } from "src/recoil/hooks/useSaveLinking"
import { RootStackScreenProps } from "src/types.d"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { Button } from "src/components/parts/buttons/Button"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { PC_BREAKPOINT } from "src/constants/Screen"

const caution = `※招待コードは団体の代表者様へ直接お問い合わせください。`

export const JoinOrganization = memo<RootStackScreenProps<"JoinOrganization">>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const isMigrateFlg = route.params?.isMigrateFlg
  const gotoRegisterOrganizationProfile = useCallback(
    (
      organizationId: string,
      organizationName: string,
      requirementForMemberProfile?: string | null,
      customQuestions?: TeamCustomQuestion[] | null
    ) =>
      navigation.navigate(Screens.RegisterProfile, {
        organizationId,
        organizationName,
        requirementForMemberProfile,
        customQuestions,
      }),
    [navigation]
  )
  const gotoOrganizationDetail = useCallback(
    (id: string) => {
      navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: OrganizationManagerScreens.OrganizationDetail,
          params: { organizationId: id },
        },
      })
    },
    [navigation]
  )

  const { code, setCode, next, isProcessing, isDisabled } = useJoinOrganizationData({
    gotoRegisterOrganizationProfile,
    gotoOrganizationDetail,
    invitationCode: route.params?.code,
    migrate: {
      isMigrateFlg: route.params?.isMigrateFlg,
      name: route.params?.name,
      orgId: route.params?.orgId,
      role: route.params?.role,
    },
  })

  useSaveLinking(useCallback(() => navigation.navigate(Screens.JoinOrganization, { code }), [navigation, code]))

  const insets = useSafeAreaInsets()

  return (
    <CustomKeyboardAvoidingView>
      <ScrollView style={styles.scrollArea}>
        <View style={isLargeScreen ? { alignItems: "center" } : {}}>
          <View style={[styles.section, isLargeScreen ? { width: 600 } : {}]}>
            <ItemLabel
              label="招待コードを入力(8桁)"
              RightComponent={<InputLengthCounter text={code} maxLength={8} unit={"桁"} />}
            />
            <CustomTextInput
              style={[styles.codeInput, isMigrateFlg ? { color: "black" } : {}]}
              value={code}
              onChangeText={(text) => {
                const numericValue = text.replace(/[^0-9]/g, "")
                setCode(numericValue)
              }}
              maxLength={8}
              editable={!isProcessing && !isMigrateFlg}
              keyboardType="number-pad"
            />
            {isMigrateFlg ? <></> : <Text style={styles.caution}>{caution}</Text>}
          </View>
        </View>
      </ScrollView>
      <View
        style={[
          styles.section,
          styles.tailSection,
          { paddingBottom: insets.bottom + 10 },
          isLargeScreen ? { alignItems: "center" } : {},
        ]}
      >
        <TextButton
          style={isLargeScreen ? { width: 300 } : {}}
          title="次へ"
          buttonType={ButtonType.Primary}
          onPress={next}
          disabled={isDisabled}
          isLoading={isProcessing}
        />
      </View>
    </CustomKeyboardAvoidingView>
  )
})

export const options: ScreenOptions = ({ navigation }) => ({
  ...commonHeaderOptionsOnNestedScreen,
  title: "団体参加",
  headerLeft: (props) => (
    <Button
      onPress={() => {
        return navigation.reset({
          index: 0,
          routes: [
            {
              name: Screens.Root,
              params: {
                screen: RootTabs.OrganizationManagerTab,
                params: { screen: OrganizationManagerScreens.OrganizationManager },
              },
            },
          ],
        })
      }}
    >
      <BackIcon
        fill={Number(props.screenLayout?.width) >= PC_BREAKPOINT && Platform.OS === "web" ? Colors.orange : Colors.white3}
        {...props}
      ></BackIcon>
    </Button>
  ),
})

JoinOrganization.displayName = "JoinOrganization"

const styles = StyleSheet.create({
  scrollArea: {
    flex: 1,
  },
  section: {
    padding: 16,
  },
  tailSection: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
  codeInput: {
    marginTop: 8,
  },
  caution: {
    marginTop: 8,
  },
})
