import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config"
import { initializeApp } from "firebase/app"

import Constants from "expo-constants"
import * as firebaseConfigPrd from "../../../firebase/prd/firebase"
import * as firebaseConfigStg from "../../../firebase/stg/firebase"
import * as firebaseConfigDev from "../../../firebase/dev/firebase"
import { RemoteConfigBanners } from "../../constants/RemoteConfigBanners"

// https://firebase.google.com/docs/remote-config/get-started?platform=web
export class RemoteConfigWeb {
  webRemoteConfig

  constructor() {
    const env = Constants.expoConfig?.extra?.env ?? "dev"
    const config = env === "prd" ? firebaseConfigPrd : env === "stg" ? firebaseConfigStg : firebaseConfigDev
    const fetchInterval = ["dev", "stg"].includes(env) ? 0 : 43200000

    const app = initializeApp(config.firebaseConfig)
    this.webRemoteConfig = getRemoteConfig(app)
    this.webRemoteConfig.settings = {
      minimumFetchIntervalMillis: fetchInterval,
    }
    this.webRemoteConfig.defaultConfig = {
      [RemoteConfigBanners.Home]: JSON.stringify({ img: "", url: "" }),
      [RemoteConfigBanners.Renraku]: JSON.stringify({ img: "", url: "" }),
    }
    this.fetchAndActivate()
  }

  getJson(key: RemoteConfigBanners): { img: string, url: string } {
    const str = getValue(this.webRemoteConfig, key).asString()
    try {
      const { img = "", url = "" } = JSON.parse(str)
      return { img, url }
    } catch {
      return { img: "", url: "" }
    }
  }

  fetchAndActivate(): Promise<boolean> {
    return fetchAndActivate(this.webRemoteConfig)
  }
}

export const remoteConfigWrap = new RemoteConfigWeb()
