import { Result } from "src/utils/Result";
import { ResourceUnit } from "src/types/resource/ResourceUnit";
import { getResourcePutUrl } from "../resource/getResourcePutUrl";
type SuccessResponse = unknown

type FailureResponse = {
  message: string
}
const ERROR_MESSAGE = "画像のアップロードに失敗しました。ネットワークに接続されているかを確認し、再度保存をしてください。";

type UploadAttachmentFileInput = ResourceUnit & {
  accessToken: string
  fileData: File
}

export const uploadMatchingAttachmentFile = async ({
                                             accessToken,
                                             fileData,
                                             ...resourceUnit
                                           }: UploadAttachmentFileInput): Promise<Result<SuccessResponse, FailureResponse>> => {
  if (resourceUnit.type === "matchingOfferAttachmentFile") {
    const urls = await getResourcePutUrl({ accessToken, ...resourceUnit });
    if (urls != null) {
      return await fetch(urls.url, {
        method: "PUT",
        body: fileData
      }).then(_ => Result.Ok<SuccessResponse>(undefined))
        .catch(_ => {
          return Result.Error<FailureResponse>({
            message: ERROR_MESSAGE
          });
        });
    } else {
      return Result.Error<FailureResponse>({
        message: ERROR_MESSAGE
      });
    }
  }
  return Result.Ok(undefined);
};

type UploadAttachmentThumbnailFileInput = ResourceUnit & {
  accessToken: string
  fileData: File
}

export const uploadMatchingAttachmentThumbnailFile = async ({
                                                      accessToken,
                                                      fileData,
                                                      ...resourceUnit
                                                    }: UploadAttachmentThumbnailFileInput): Promise<Result<SuccessResponse, FailureResponse>> => {
  if (resourceUnit.type === "matchingOfferAttachmentThumbnail") {
    const urls = await getResourcePutUrl({ accessToken, ...resourceUnit });
    if (urls != null) {
      return await fetch(urls.url, {
        method: "PUT",
        body: fileData
      }).then(_ => Result.Ok<SuccessResponse>(undefined))
        .catch(_ => {
          return Result.Error<FailureResponse>({
            message: ERROR_MESSAGE
          });
        });
    } else {
      return Result.Error<FailureResponse>({
        message: ERROR_MESSAGE
      });
    }
  }
  return Result.Ok(undefined);
};
