import { memo, useCallback, useEffect, useState, useMemo } from "react"
import { FlatList, ListRenderItemInfo, StyleSheet, Text, View } from "react-native"
import { DrawerScreenOptions } from "src/navigation/ContactNetworkDrawer"
import { Colors } from "src/constants/Colors"
import { MigrationIcon } from "src/components/parts/icons/drawer/MigrationIcon"
import { DrawerStatus } from "src/components/parts/contactNetworkTab/DrawerStatus"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { CustomAlert } from "src/utils/CustomAlert"
import { ContactListFooterMargin } from "src/components/parts/contactNetworkTab/ContactListFooter"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { ContactItem } from "src/components/parts/ContactItem"
import { Screens } from "src/constants/Screens"
import { ContactNetworkDrawerProps } from "src/types.d"
import { ContactNetworkDrawers } from "src/constants/ContactNetworkDrawers"
import { DrawerIconContainer } from "src/components/parts/contactNetworkTab/DrawerIconContainer"
import { ContactFilterType } from "src/types/contact/ContactFilter"
import { ContactQueryBuilder } from "src/components/parts/contactNetworkTab/ContactQueryBuilder"
import { useContactNetworkDrawerNavEffect } from "src/recoil/hooks/contact/useContactNetworkDrawerNavEffect"
import { RootTabs } from "src/constants/RootTabs"
import { Buckets } from "src/aws/customAPI"
import { requestDataSelector } from "src/recoil/selectors/auth/requestDataSelector"
import { useRecoilValue } from "recoil"
import { getUnreadThreadCount } from "src/apis/contact/getUnreadThreadCount"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { MainLayout } from "src/layouts/MainLayout"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useFetcher } from "src/hooks/useFetcher"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { updateFavoriteContactMigration } from "src/apis/contact/updateFavoriteContactMigration"
import { useRefresher } from "src/hooks/useRefresher"
import { migrationContactsRequestIdState } from "src/recoil/atoms/contact/migrationContactsRequestIdState"
import { useContactDetailDataRefresher } from "src/recoil/hooks/refresher/useContactDetailDataRefresher"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { ContactFilterValue } from "src/types/contact/ContactFilter"
import { deleteMigrationContact } from "src/apis/contact/deleteMigrationContact"
import { wait } from "src/utils/wait"
import { useRecoilState, useResetRecoilState } from "recoil"
import { migrationContactFavoriteFlgsCacheState } from "src/recoil/atoms/contact/contactFavoriteFlgsCacheState"
import { migrationContactsConcatSelectorFamily } from "src/recoil/selectors/contact/migrationContactsConcatSelectorFamily"
import { migrationContactsConcatRequestIdState } from "src/recoil/atoms/contact/migrationContactsConcatRequestIdState"
import { myProfileRequestIdState } from "src/recoil/atoms/organization/myProfile/myProfileRequestIdState"
import { ScrollView } from "react-native-gesture-handler"

export const useMigrationScreenData = () => {
  const [searchText, setSearchText] = useState("")
  const [filters, setFilters] = useState<ContactFilterValue[]>([])

  const { value: selectedMyOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(() => selectedMyOrganization?.id, [selectedMyOrganization])
  const { value: myProfile } = useAsyncSelector(myProfileSelectorFamily(organizationId))
  const refreshMyProfile = useRefresher(myProfileRequestIdState)
  const isMailMigrated = useMemo(() => myProfile?.isMailMigrated || false, [myProfile])

  const [nextTokens, setNextTokens] = useState<string[]>([])
  useEffect(() => {
    setNextTokens([])
  }, [organizationId, setNextTokens])
  const setSearchTextHelper = useCallback((text: string) => {
    setNextTokens([])
    setSearchText(text)
  }, [])
  const params = useMemo(
    () => ({
      searchText: searchText !== "" ? searchText : undefined,
      filters: filters.length !== 0 ? filters : undefined,
    }),
    [searchText, filters]
  )
  const { value, isLoading } = useAsyncSelector(migrationContactsConcatSelectorFamily({ ...params, nextTokens }))

  const [favoriteFlgsCache, setFavoriteFlgsCache] = useRecoilState(migrationContactFavoriteFlgsCacheState)
  const resetFavoriteFlgsCache = useResetRecoilState(migrationContactFavoriteFlgsCacheState)

  const _refreshContacts = useRefresher(migrationContactsRequestIdState)
  const _refreshContactsConcat = useRefresher(migrationContactsConcatRequestIdState)
  const refreshContacts = useCallback(() => {
    _refreshContacts()
    _refreshContactsConcat()
  }, [_refreshContacts, _refreshContactsConcat])

  const setFiltersHelper = useCallback(
    (filters: ContactFilterValue[]) => {
      setNextTokens([])
      setFilters(filters)
      refreshContacts()
    },
    [refreshContacts]
  )

  const requestDataResult = useRequestData()
  const refreshContactDetailData = useContactDetailDataRefresher()

  const { fetch: execUpdateFavoriteContact } = useFetcher(
    useCallback(
      async ({ contactId, isFavorite }: { contactId: string; isFavorite: boolean }) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accountId, accessToken } = requestDataResult.content
        if (accountId == null || accessToken == null) {
          return
        }
        const result = await updateFavoriteContactMigration({ accessToken, contactId, isFavorite })
        if (!result.isOk) {
          CustomAlert.alert("エラー", result.error.message)
          return
        }
        if (result.isOk && filters.includes("favorite") && value) {
          value.contacts = value.contacts.filter((e: { id: string }) => e.id && e.id !== contactId)
        }
        setFavoriteFlgsCache((prev) => ({ ...prev, [contactId]: isFavorite }))
      },
      [requestDataResult, setFavoriteFlgsCache, value, filters]
    )
  )

  const { fetch: execDeleteContact } = useFetcher(
    useCallback(
      async (contactId: string) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accessToken } = requestDataResult.content
        if (accessToken == null) {
          return
        }
        const isConfirmed = await CustomAlert.confirm("確認", "この連絡を完全に削除しますか？\n削除後は復元できません。")
        if (!isConfirmed) {
          return
        }
        const result = await deleteMigrationContact({ accessToken, migrationContactId: contactId })

        if (result.isOk) {
          await CustomAlert.alert("完了", "連絡を削除しました。")
          await wait(2000)
          refreshContacts()
          resetFavoriteFlgsCache()
        } else {
          await CustomAlert.alert("エラー", result.error.message)
        }
      },
      [requestDataResult, refreshContacts, resetFavoriteFlgsCache]
    )
  )

  return {
    searchText,
    setSearchText: setSearchTextHelper,
    filters,
    setFilters: setFiltersHelper,
    value,
    isLoading,
    execUpdateFavoriteContact,
    execDeleteContact,
    refreshContactDetailData,
    refreshContacts,
    refreshMyProfile,
    isMailMigrated,
    favoriteFlgsCache,
    setNextTokens,
    organizationId,
  }
}

export type MigrationParams = {
  organizationId?: string
}

export const MigrationScreen = memo<ContactNetworkDrawerProps<typeof ContactNetworkDrawers.MigrationScreen>>(
  ({ navigation, route }) => {
    const isLargeScreen = useCheckPCScreen()
    const [unreadThreadCountBuckets, setUnreadThreadCountBuckets] = useState<Buckets[]>([])
    const request = useRecoilValue(requestDataSelector)
    const {
      searchText,
      setSearchText,
      filters,
      setFilters,
      value,
      isLoading,
      execUpdateFavoriteContact,
      execDeleteContact,
      refreshContactDetailData,
      refreshContacts,
      isMailMigrated,
      favoriteFlgsCache,
      setNextTokens,
      organizationId,
    } = useMigrationScreenData()

    useEffect(() => {
      const contactIds = value?.contacts.map((el) => el.id)
      if (request) getUnreadThreadCount({ ...request, contactIds }).then((data) => setUnreadThreadCountBuckets(data.buckets))
    }, [request, value])

    function calculateUnreadThreadCount(contactId: string, unreadThreadCountBuckets: Buckets[]) {
      const unreadThread = unreadThreadCountBuckets?.find((el) => el.key === contactId)
      return unreadThread?.doc_count ?? 0
    }

    // web版のリロードに対応するため、クエリパラメータを付与してリダイレクト
    useContactNetworkDrawerNavEffect({
      queryParamOrganizationId: route.params?.organizationId,
      screenName: ContactNetworkDrawers.MigrationScreen,
      redirect: (organizationId) => {
        navigation.replace(Screens.Root, {
          screen: RootTabs.ContactNetworkDrawer,
          params: {
            screen: ContactNetworkDrawers.MigrationScreen,
            params: { organizationId: organizationId },
          },
        })
      },
    })

    const gotoContactDetail = useCallback(
      (contactId: string) => {
        if (organizationId == null) return
        refreshContactDetailData()
        navigation.navigate(Screens.ContactDetail, { contactId, organizationId, viewMode: "Migration" })
      },
      [navigation, refreshContactDetailData, organizationId]
    )

    const ListItem = useCallback(
      (listRenderItemInfo: ListRenderItemInfo<ContactOverviewModel>) => {
        return (
          <View style={styles.contentWrapper}>
            <ContactItem
              contactId={listRenderItemInfo.item.id}
              viewType="Migration"
              title={listRenderItemInfo.item.title}
              contactType={listRenderItemInfo.item.contactType}
              sentDate={listRenderItemInfo.item.sentDate}
              timeLimitDate={listRenderItemInfo.item.deadlineDate}
              senderId={listRenderItemInfo.item.senderId}
              senderName={listRenderItemInfo.item.senderName}
              isSenderDeleted={listRenderItemInfo.item.isSenderDeleted}
              isFavorite={listRenderItemInfo.item.isFavorite}
              hasAttachment={listRenderItemInfo.item.isAttachmentExists}
              onIsFavoriteChange={() =>
                execUpdateFavoriteContact({
                  contactId: listRenderItemInfo.item.id,
                  isFavorite: !(favoriteFlgsCache?.[listRenderItemInfo.item.id] ?? listRenderItemInfo.item.isFavorite),
                })
              }
              favoriteFlgsCache={favoriteFlgsCache}
              onContactPress={() => gotoContactDetail(listRenderItemInfo.item.id)}
              onDeletePress={() => execDeleteContact(listRenderItemInfo.item.id)}
              noBorder
              unreadThreadCount={calculateUnreadThreadCount(listRenderItemInfo.item.id, unreadThreadCountBuckets)}
            />
          </View>
        )
      },
      [favoriteFlgsCache, unreadThreadCountBuckets, execUpdateFavoriteContact, gotoContactDetail, execDeleteContact]
    )
    const getMoreContacts = useCallback(() => {
      setNextTokens((prev) => (value?.nextToken != null ? [...prev, value.nextToken] : prev))
    }, [value, setNextTokens])

    if (isLoading && value == null) {
      return (
        <View style={styles.loadingIndicator}>
          <LoadingIndicator />
        </View>
      )
    }

    if (value == null) {
      return null
    }

    return (
      <MainLayout>
        <View style={[isLargeScreen ? styles.containerLarge : styles.container]}>
          <ScrollView>
            <DrawerStatus Icon={<MigrationIcon color={Colors.orange} />} label="移行トレイ" />
            <View style={styles.underLine}></View>
            {!isMailMigrated ? (
              <Text style={styles.emptyText}>連絡はありません。</Text>
            ) : (
              <>
                <ContactQueryBuilder
                  filterType={ContactFilterType.Migration}
                  filters={filters}
                  onFiltersChange={setFilters}
                  searchText={searchText}
                  onSearchTextChange={setSearchText}
                />
                <FlatList
                  data={value.contacts}
                  renderItem={ListItem}
                  ListFooterComponent={ContactListFooterMargin}
                  refreshControl={<CustomRefreshControl refreshing={isLoading} onRefresh={refreshContacts} />}
                  onEndReached={!isLoading ? getMoreContacts : undefined}
                  keyExtractor={(item) => item.id}
                />
              </>
            )}
          </ScrollView>
        </View>
      </MainLayout>
    )
  }
)

export const migrationScreenOptions: DrawerScreenOptions = {
  title: "移行トレイ",
  drawerIcon: (props) => (
    <DrawerIconContainer>
      <MigrationIcon {...props} color={Colors.orange} />
    </DrawerIconContainer>
  ),
}

const styles = StyleSheet.create({
  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  containerLarge: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  underLine: {
    width: "100%",
    height: 1,
    backgroundColor: Colors.white2,
  },
  emptyText: {
    paddingTop: 88,
    paddingBottom: 24,
    paddingHorizontal: 22,
    fontSize: 13,
    fontWeight: "500",
    lineHeight: 20,
    color: Colors.greyshBrown,
    textAlign: "center",
  },
  migrationButton: {
    marginVertical: 16,
    marginHorizontal: 22,
    backgroundColor: Colors.warmGrey3,
    borderRadius: 6,
    paddingVertical: 13,
    justifyContent: "center",
    alignItems: "center",
  },
  migrationButtonText: {
    fontSize: 15,
    fontWeight: "700",
    lineHeight: 20,
    color: Colors.white3,
  },
  reloadButtonContainer: {
    marginTop: 12,
    alignItems: "center",
  },
  contentWrapper: {
    paddingHorizontal: 16,
    paddingTop: 10,
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    alignContent: "center",
  },
  centerLayout: {
    display: "flex",
    alignItems: "center",
  },
})
