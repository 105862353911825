import { selectorFamily } from "recoil"
import { contactDetailRequestIdState } from "src/recoil/atoms/contact/contactDetailRequestIdState"
import {
  getOutBoxContactDetail,
  getMigrationContact,
  getReservedContact,
  Item,
  getInBoxContactDetail,
} from "src/apis/contact/getContactDetail"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { CustomAlert } from "src/utils/CustomAlert"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"

export const contactDetailSelectorFamily = selectorFamily<
  Item | undefined,
  { contactId: string | undefined; type: "Inbox" | "Outbox" | "Reserved" | "Migration" }
>({
  key: "selectors/contact/contactDetailSelectorFamily",
  get:
    ({ contactId, type }) =>
    async ({ get }) => {
      get(contactDetailRequestIdState)
      if (contactId == null) {
        return
      }
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null) {
        return
      }
      const organization = get(selectedMyOrganizationSelector)
      const organizationId = organization?.id
      if (organizationId == null) {
        return
      }
      const myProfile = get(myProfileSelectorFamily(organizationId))
      const myMemberId = myProfile?.memberId
      if (myMemberId == null) {
        return
      }

      const result =
        type === "Inbox"
          ? await getInBoxContactDetail({ accessToken, myMemberId, contactId, isSent: false })
          : type === "Outbox"
          ? await getOutBoxContactDetail({ accessToken, myMemberId, contactId, isSent: true })
          : type === "Reserved"
          ? await getReservedContact({ accessToken, contactId })
          : await getMigrationContact({ accessToken, contactId, organizationId })
      if (!result.isOk) {
        CustomAlert.alert("エラー", result.error.message)
        return
      }
      return result.content
    },
})
