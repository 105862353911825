import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"

import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { useRequestData } from "src/recoil/hooks/useRequestData"

import { useFetcher } from "src/hooks/useFetcher"
import { CustomAlert } from "src/utils/CustomAlert"
import { updateTeamMemberGrade } from "src/apis/team/updateTeamMemberGrade"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"

type Params = {
  organizationId: string
  goToOrganizationDetail: (organizationId: string) => void
}

export const useTeamMemberGradeUpdateData = ({ organizationId, goToOrganizationDetail }: Params) => {
  const { value: organizationDetail, isLoading: isLoadingOrganizationDetail } = useAsyncSelector(
    organizationDetailSelectorFamily(organizationId)
  )
  const customQuestions = useMemo(() => organizationDetail?.customQuestions || [], [organizationDetail])
  const requestDataResult = useRequestData()
  const { value: tokens } = useAsyncSelector(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const { fetch: execUpdateMemberGradeDetail, isFetching: isUpdatingMemberGradeDetail } = useFetcher(
    useCallback(async () => {
      if (!requestDataResult.isOk) {
        return
      }
      if (accessToken == null) {
        return
      }
      const isConfirmed = await CustomAlert.confirm(
        "確認",
        "学年の一斉更新を実行した場合、\nこの作業は元に戻すことはできません。\n更新しますか？"
      )
      if (!isConfirmed) {
        return
      }
      const result = await updateTeamMemberGrade({
        accessToken,
        teamId: organizationId,
      })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      await CustomAlert.alert("完了", "学年の一斉更新を完了しました。")
      goToOrganizationDetail(organizationId)
    }, [accessToken, requestDataResult, organizationId, goToOrganizationDetail])
  )

  return {
    organizationDetail,
    isLoadingOrganizationDetail,
    customQuestions,
    execUpdateMemberGradeDetail,
    isUpdatingMemberGradeDetail,
  }
}
