import { Result } from "src/utils/Result"
import { execApi } from "src/apis/execApi"
import { UpdateTeamMemberGrade } from "src/aws/customAPI"

type UpdateTeamMemberGradeParams = {
  accessToken: string
  teamId: string
}

type UpdateTeamMemberGradeSuccess = unknown

type UpdateTeamMemberGradeFailure = {
  message: string
}

export const updateTeamMemberGrade = async (params: UpdateTeamMemberGradeParams): Promise<Result<UpdateTeamMemberGradeSuccess, UpdateTeamMemberGradeFailure>> => {
  const { accessToken, teamId } = params
  return execApi(
    () => UpdateTeamMemberGrade(accessToken, { input: { teamId: teamId }}),
    _ => undefined,
    {
      identifiers: ["updateTeamMemberGrade", "updateTeamMemberGradeApi"],
      defaultErrorMessage: "学年の一斉更新に失敗しました。",
    },
  )
}
