import { OrganizationManagerStackScreenProps } from "src/types.d"
import { Screens } from "src/constants/Screens"
import { memo, useCallback } from "react"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { StyleSheet, Text, View } from "react-native"
import { Colors } from "src/constants/Colors"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { TeamCustomQuestion } from "src/aws/API"
import { useTeamMemberGradeUpdateData } from "src/recoil/hooks/organization/useTeamMemberGradeUpdateData"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { RootTabs } from "src/constants/RootTabs"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"

export type OrganizationMemberGradeParams = {
  organizationId: string
}
type Props = OrganizationManagerStackScreenProps<typeof Screens.OrganizationMemberGrade>

export const OrganizationMemberGrade = memo<Props>(({ navigation, route }) => {
  const { organizationId } = route.params
  const { allowEditOrganizationDetail } = useCheckAllowAccountPermission({ organizationId })
  const goToOrganizationDetail = useCallback(() => {
    navigation.navigate(Screens.Root, {
      screen: RootTabs.OrganizationManagerTab,
      params: {
        screen: OrganizationManagerScreens.OrganizationDetail,
        params: { organizationId: organizationId },
      },
    })
  }, [navigation, organizationId])

  const { organizationDetail, isLoadingOrganizationDetail, execUpdateMemberGradeDetail, isUpdatingMemberGradeDetail } =
    useTeamMemberGradeUpdateData({ organizationId, goToOrganizationDetail })
  const isLargeScreen = useCheckPCScreen()
  if (!allowEditOrganizationDetail || !organizationDetail?.customQuestions?.includes(TeamCustomQuestion.grade)) {
    return (
      <View style={styles.loadingContainer}>
        <LoadingIndicator />
      </View>
    )
  }
  return (
    <CustomKeyboardAvoidingView style={isLargeScreen ? styles.centerLayout : {}}>
      <CustomScrollView style={[styles.container, isLargeScreen ? styles.oneColumn : {}]}>
        <View style={styles.memberGradeContainer}>
          <View style={styles.textContainer}>
            <Text style={styles.title}>学年の一斉更新</Text>
            <Text style={styles.descriptionText}>
              この機能は、団体に所属する全メンバーの学年を1つ上げます。（例：小1→小2、中3→高1）{`\n`}
              学年が「高3」の方はそれ以上の学年は無いため、学年の設定がなくなります。{`\n`}
              なお、学年を設定していないメンバーの方には何も影響はありません。{`\n`}
              {organizationDetail?.lastGradeUpdatedAt ? (
                <>
                  前回この機能が使われたのは、
                  <Text style={styles.lastGradeUpdatedAtText}>{organizationDetail.lastGradeUpdatedAt}</Text>です。
                </>
              ) : (
                "前回この機能が使われた記録はありません。"
              )}
              {`\n`}
              この機能を使う場合は、実行ボタンを押してください。{`\n`}
              {`\n`}
              尚、<Text style={{ color: Colors.red }}>学年の一斉更新を実行した場合、この作業は元に戻せません</Text>
              ので、十分ご確認の上行ってください。
            </Text>
          </View>
          <View style={isLargeScreen ? styles.centerLayout : {}}>
            <View style={[styles.textButtonContainer, isLargeScreen ? styles.centerLayout : {}]}>
              <TextButton
                title="キャンセル"
                buttonType={ButtonType.Secondary}
                onPress={goToOrganizationDetail}
                style={isLargeScreen ? styles.buttonsPC : {}}
              />
            </View>
            <View style={[styles.textButtonContainer, isLargeScreen ? styles.centerLayout : {}]}>
              <TextButton
                title="学年を一斉に更新する"
                buttonType={ButtonType.Primary}
                onPress={execUpdateMemberGradeDetail}
                isLoading={isLoadingOrganizationDetail || isUpdatingMemberGradeDetail}
                style={isLargeScreen ? styles.buttonsPC : {}}
              />
            </View>
          </View>
        </View>
      </CustomScrollView>
    </CustomKeyboardAvoidingView>
  )
})

export const teamMemberGradeOption: ScreenOptions = {
  ...commonHeaderOptions,
  title: "学年の一斉更新",
}

const styles = StyleSheet.create({
  centerLayout: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    flex: 1,
  },
  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  descriptionText: {
    fontSize: 14,
    lineHeight: 24,
    color: Colors.black2,
  },
  itemLabelWrapper: {
    marginTop: 24,
  },
  lastGradeUpdatedAtText: {
    fontSize: 14,
    lineHeight: 24,
    color: Colors.red,
    fontWeight: "bold",
  },
  textButtonContainer: {
    marginTop: 24,
  },
  oneColumn: {
    width: 600,
    maxWidth: 1020,
    paddingLeft: 30,
    paddingRight: 30,
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
  memberGradeContainer: {
    paddingVertical: 24,
    paddingHorizontal: 16,
    gap: 24,
    borderColor: Colors.borderColor,
  },
  textContainer: {
    gap: 16,
  },
  title: {
    fontSize: 16,
    fontWeight: "600",
    lineHeight: 22.4,
    textAlign: "left",
    color: Colors.redBold,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
})
