import { getAccountTeamMember, getTeam } from "src/aws/customAPI"
import { TeamCustomQuestion } from "src/aws/API"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"
import dayjs from "dayjs"

type Group = {
  id: string
  name: string
}

type ProfileInOrganization = {
  id: string
  name: string
}

export type Organization = {
  id: string
  organizationName: string
  organizationNumber: string
  prefecture: number
  city: { id: string; label: string }
  category: { id: string; label: string }
  subCategory: { id: string; label: string }
  profile: string
  memberNumber: number
  pendingInvitationsCount: number
  groups: Group[]
  profileInOrganization: ProfileInOrganization
  isMembersPublic: boolean
  requirementForMemberProfile?: string
  customQuestions?: TeamCustomQuestion[]
  migrationId: number | string
  paidFunctionEnabled: boolean
  showAdsFlg: boolean
  lastGradeUpdatedAt: string | null
}

type Params = {
  accessToken: string
  accountId: string
  organizationId: string
  memberNumber: number
  pendingInvitationsCount: number
  groups: Group[]
}

export const getOrganizationDetail = async ({
  accessToken,
  accountId,
  organizationId,
  memberNumber,
  pendingInvitationsCount,
  groups,
}: Params): Promise<Organization | undefined> => {
  const result = await execApi(
    () =>
      getTeam(accessToken, {
        id: organizationId,
        appInfo,
      }),
    (res) => res.getTeam
  )
  if (!result.isOk) return

  const team = result.content

  const profileInOrganization: ProfileInOrganization | undefined = await getAccountTeamMember(accessToken, {
    accountId: accountId,
    teamId: organizationId,
    appInfo,
  })
    .then((res) => res?.data?.getAccount.teamMembers.items[0])
    .then(
      (member) =>
        member && {
          id: member.id,
          name: member.nickname,
        }
    )

  if (profileInOrganization == null) {
    return
  }

  return (
    team && {
      id: team.id,
      organizationName: team.name,
      organizationNumber: team.invitationCode,
      prefecture: parseInt(team.prefecture.id),
      city: team.city,
      category: team.category,
      subCategory: team.activity,
      profile: team.description,
      memberNumber: memberNumber,
      pendingInvitationsCount: pendingInvitationsCount,
      groups: groups,
      profileInOrganization: profileInOrganization,
      isMembersPublic: team.showMemberListFlg,
      requirementForMemberProfile: team.requirementForMemberProfile,
      customQuestions: team.customQuestions,
      migrationId: team.migrationId,
      paidFunctionEnabled: team.paidFunctionEnabled ?? true,
      showAdsFlg: team.showAdsFlg ?? true,
      lastGradeUpdatedAt: team.lastGradeUpdatedAt ? dayjs(team?.lastGradeUpdatedAt).format("YYYY-MM-DD HH:mm:ss") : null,
    }
  )
}
