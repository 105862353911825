import { ImageExtension } from "src/aws/API"
import { CustomAlert } from "src/utils/CustomAlert"

export const getImageExtension = (uri: string): ImageExtension | undefined => {
  const extension = getExtension(uri)

  if (!isImageExt(extension)) {
    CustomAlert.alert("エラー", "画像の形式が無効です。")
    return undefined
  }
  return extension as ImageExtension
}

export const getExtension = (uri: string): string => {
  const split = uri.split(".")
  return split.length > 0 ? split.slice(-1)[0].toLowerCase() : ""
}

export const isImageExt = (extension: string): boolean => {
  return (Object.values(ImageExtension) as string[]).includes(extension)
}
export const ONE_MONTH = 30 * 24 * 60 * 60 * 1000
export const isExpired = (endDateStr: Date | string) => {
  const endDate = new Date(endDateStr).getTime()
  const currentDate = new Date().getTime() + 9 * ONE_HOUR
  const timeDifference = currentDate - endDate
  return timeDifference > ONE_MONTH
}
export const ONE_DAY = 24 * 60 * 60 * 1000
export const ONE_HOUR = 60 * 60 * 1000
export const isEndPublishDatetime = (time: Date | string) => {
  const today = new Date().getTime() + 9 * ONE_HOUR
  const timeEndDate = new Date(time).getTime() + ONE_DAY - 1
  return today > timeEndDate
}
