import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { Image, Platform, ScrollView, StyleSheet, Text, View } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { ButtonType, IconButton } from "src/components/parts/buttons/IconButton"
import { DoubleButtonFooter } from "src/components/parts/DoubleButtonFooter"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { ChevronRightIcon } from "src/components/parts/icons/ChevronRightIcon"
import { CreateContactIcon } from "src/components/parts/icons/CreateContactIcon"
import { DoubleQuoteStart } from "src/components/parts/icons/DoubleQuoteStart"
import { EditIcon } from "src/components/parts/icons/EditIcon"
import { JoinIcon } from "src/components/parts/icons/JoinIcon"
import { MemberIcon } from "src/components/parts/icons/MemberIcon"
import { PlusIcon } from "src/components/parts/icons/PlusIcon"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { CreateNewContactScreens } from "src/constants/CreateNewContactScreens"
import { CreateOrEditGroupScreens } from "src/constants/CreateOrEditGroupScreens"
import { CreateOrEditOrganizationScreens } from "src/constants/CreateOrEditOrganizationScreens"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useAuthorization } from "src/recoil/hooks/authorization/useAuthorization"
import { useOrganizationDetailData } from "src/recoil/hooks/screens/useOrganizationDetailData"
import { OrganizationManagerStackScreenProps } from "src/types.d"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { MaxCount } from "src/constants/MaxCount"
import { CustomAlert } from "src/utils/CustomAlert"
import { useAsyncState } from "src/hooks/useAsyncState"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { RootTabs } from "src/constants/RootTabs"
import { Analytics } from "src/tags/analytics/Analytics"
import { useRecoilState, useRecoilValue } from "recoil"
import { routeNameState } from "src/recoil/atoms/routeNameState"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { defaultTeam } from "src/constants/defaultTeam"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { MainLayout } from "src/layouts/MainLayout"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { MatchingButton } from "src/components/parts/buttons/MatchingButton"
import { hasMatchingNoticeSelector } from "src/recoil/selectors/matching/hasMatchingNoticeSelector"
import { RootDrawer } from "src/constants/RootDrawer"
import { MatchingDrawers } from "src/constants/MatchingDrawers"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"
import { useRefresher } from "src/hooks/useRefresher"
import { teamMembersRequestIdState } from "src/recoil/atoms/organization/teamMembersRequestIdState"
import * as WebBrowser from "expo-web-browser"
import { OrderStatus } from "src/apis/paidTeam/getOrderDetail"
import { organizationMemberImageRefresherState } from "src/recoil/atoms/organization/organizationMemberImageRefresherState"
import { getOrderDetailState } from "src/recoil/selectors/paidTeam/orderDetailState"
import { helpPaidURL } from "src/constants/links/guide"
import { organizationRefreshPermissionState } from "src/recoil/atoms/organization/organizationRefreshPermissionState"
import { teamPermissionRequestIdState } from "src/recoil/atoms/organization/teamPermissionRequestIdState"
import { TeamCustomQuestion } from "src/aws/API"

export type OrganizationDetailParams = {
  organizationId: string
}

type Props = OrganizationManagerStackScreenProps<typeof OrganizationManagerScreens.OrganizationDetail>

export const OrganizationDetail = memo<Props>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const [needRefresh, setNeedRefresh] = useRecoilState(organizationMemberImageRefresherState)
  const needRefreshPermission = useRecoilValue(organizationRefreshPermissionState)
  const refreshPermission = useRefresher(teamPermissionRequestIdState)
  const { organizationId } = useMemo(() => route.params, [route.params])
  const gotoOrganizationTab = useCallback(
    () => navigation.navigate(OrganizationManagerScreens.OrganizationManager),
    [navigation]
  )
  const [selectedOrganizationId, setSelectedOrganizationId] = useAsyncState(selectedMyOrganizationState)
  const routeName = useRecoilValue(routeNameState)
  useEffect(() => {
    if (routeName === OrganizationManagerScreens.OrganizationDetail && selectedOrganizationId !== organizationId) {
      if (isLargeScreen && selectedOrganizationId) {
        navigation.replace(Screens.Root, {
          screen: RootTabs.OrganizationManagerTab,
          params: {
            screen: OrganizationManagerScreens.OrganizationDetail,
            params: { organizationId: selectedOrganizationId },
          },
        })
      } else {
        setSelectedOrganizationId(organizationId)
      }
    }
  }, [organizationId, selectedOrganizationId, setSelectedOrganizationId, routeName, isLargeScreen, navigation])

  const {
    organizationDetail,
    groupCount,
    organizationImageUrl,
    isLoadingOrganizationDetail,
    refresh,
    refreshOrganizationImageUrl,
    refreshMyProfileFormData,
    refreshPendingInvitations,
    defaultShowGroupsCount,
    showAllGroups,
    setShowAllGroups,
    insets,
    myProfileImageUrl,
    refreshMyProfileImageUrl,
    setOrganizationId,
    quit,
    isQuitting,
    resetCreateNewContactScreenData,
    setListMembers,
    orderDetailValues,
    setApplicantId,
    refreshOrder,
    refreshOrganizationDetail,
  } = useOrganizationDetailData({ organizationId, gotoOrganizationTab })

  useEffect(() => {
    if (needRefresh) {
      setNeedRefresh(false)
      refreshMyProfileImageUrl()
    }
  }, [needRefresh, refreshMyProfileImageUrl, setNeedRefresh])

  useEffect(() => {
    if (needRefreshPermission) {
      refreshPermission()
      refreshOrganizationDetail()
      refreshOrder()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefreshPermission])

  const refreshMembers = useRefresher(teamMembersRequestIdState)
  const { allowTeamMatching } = useAuthorization({ organizationId })
  const [orderDetail, setOrderDetail] = useRecoilState(getOrderDetailState)
  const [paidStatusText, setPaidStatusText] = useState("")
  const [paidStatusStype, setPaidStatusStype] = useState({ marginLeft: 30, color: Colors.black2 })

  const isShowAds = organizationDetail?.showAdsFlg ?? true
  const paidFunctionEnabled = organizationDetail?.paidFunctionEnabled ?? true
  const showStar = !isShowAds && paidFunctionEnabled

  useEffect(() => {
    const orderDetail =
      orderDetailValues !== undefined && typeof orderDetailValues !== "string"
        ? orderDetailValues.usedOrder.order
          ? orderDetailValues.usedOrder.order
          : orderDetailValues.preOrder.order
        : undefined
    orderDetail && setOrderDetail(orderDetail)

    setPaidStatusStype({ marginLeft: 30, color: Colors.black2 })
    if (!paidFunctionEnabled && orderDetail?.orderStatus === OrderStatus.Use) {
      setPaidStatusStype({ marginLeft: 30, color: Colors.red })
      setPaidStatusText("団体有料版機能停止中")
    } else if (!paidFunctionEnabled) {
      setPaidStatusText("無料版を利用")
    } else if (orderDetail?.orderStatus === OrderStatus.New) {
      setPaidStatusText("有料版申込中(入金前)")
    } else if (orderDetail?.orderStatus === OrderStatus.Wait) {
      setPaidStatusText("有料版利用開始待ち")
    } else if (orderDetail?.orderStatus === OrderStatus.Use) {
      setPaidStatusText("有料版を利用")
    } else {
      setPaidStatusText("無料版を利用")
    }
  }, [orderDetailValues, paidFunctionEnabled, setOrderDetail, setPaidStatusStype])
  const {
    allowEditOrganizationDetail,
    allowViewMembers,
    allowViewPendingInvitations,
    allowCreateGroup,
    allowEditGroup,
    allowDeleteTeam,
    allowInviteMember,
    allowEditMember,
    allowCreateContact,
    listGroupAccessAll,
    listGroupIds,
    allowCreateOrder,
    allowShowOrder,
    allowTransferApplicant,
    permissionLoading,
  } = useCheckAllowAccountPermission({ organizationId })

  const listGroup = useMemo(() => {
    const apiListGroup = organizationDetail?.groups ?? []
    if (listGroupAccessAll) {
      return apiListGroup
    }
    const filteredGroups = apiListGroup.filter((group) => listGroupIds.includes(group.id))
    return filteredGroups
  }, [listGroupAccessAll, listGroupIds, organizationDetail])

  const matchableFlg = useMemo(() => organizationDetail?.category.label === "スポーツ系サークル・チーム", [organizationDetail])

  const goToEditGroupSettingsInOrganization = useCallback(
    (groupId: string) => {
      navigation.navigate(Screens.GroupMembers, {
        organizationId,
        groupId,
        allowEditGroup: allowEditGroup,
        allowEditMember,
      })
    },
    [navigation, organizationId, allowEditGroup, allowEditMember]
  )

  const goToAddGroupInOrganization = useCallback(() => {
    if (groupCount >= MaxCount.Group) {
      CustomAlert.alert(
        "エラー",
        `エラーコード010\nグループ数が上限を超えるため、作成できません。\n※1団体で作成できるグループ数は最大${MaxCount.Group}グループです。`
      )
      return
    }

    setListMembers([])
    navigation.navigate(Screens.CreateOrEditGroupStack, {
      screen: CreateOrEditGroupScreens.AddGroupInOrganization,
      params: { organizationId },
    })
  }, [navigation, organizationId, groupCount, setListMembers])

  const goToOrganizationMember = useCallback(() => {
    refreshMembers()
    navigation.navigate(Screens.OrganizationMembers, { organizationId: organizationId })
  }, [navigation, organizationId, refreshMembers])
  const goToEditOrganizationDetail = useCallback(() => {
    if (organizationDetail == null) {
      return
    }
    navigation.navigate(Screens.EditOrganizationStack, {
      screen: CreateOrEditOrganizationScreens.CreateOrEditOrganization,
      params: { ...organizationDetail, originalCategoryId: organizationDetail.category.id },
    })
  }, [navigation, organizationDetail])

  const goToMatching = () => {
    navigation.navigate(RootDrawer.MatchingDrawer, { screen: MatchingDrawers.OtherMatchingOffer })
  }

  const gotoEditMyProfile = useCallback(() => {
    if (organizationDetail == null) {
      return
    }
    refreshMyProfileFormData()
    navigation.navigate(Screens.EditMyProfile, {
      organizationId: organizationDetail.id,
      myTeamMemberId: organizationDetail.profileInOrganization.id,
    })
  }, [refreshMyProfileFormData, navigation, organizationDetail])

  const goToInvitationMember = useCallback(async () => {
    await Analytics.goToInvitation(organizationId)
    refreshPendingInvitations()
    navigation.navigate(Screens.InvitationMember, { id: organizationId })
  }, [navigation, organizationId, refreshPendingInvitations])

  const goToCreateContact = useCallback(async () => {
    await Analytics.goToCreateMail(organizationId)
    resetCreateNewContactScreenData()
    setOrganizationId(organizationId)
    navigation.navigate(Screens.CreateNewContactStack, { screen: CreateNewContactScreens.CreateNewContact })
  }, [navigation, organizationId, setOrganizationId, resetCreateNewContactScreenData])

  const gotoPendingInvitations = useCallback(() => {
    navigation.navigate(Screens.PendingInvitations, {
      id: organizationId,
      joinCount: organizationDetail?.pendingInvitationsCount,
    })
  }, [navigation, organizationDetail, organizationId])

  const goToOrganizationGroup = useCallback(() => {
    navigation.navigate(Screens.OrganizationGroups, { id: organizationId })
  }, [navigation, organizationId])

  const goToDeleteOrganization = useCallback(async () => {
    if (organizationDetail == null) {
      return
    }
    if (allowShowOrder && orderDetail.orderStatus !== OrderStatus.New) {
      const isConfirmed = await CustomAlert.confirm(
        "警告",
        "この団体は、団体有料版を利用中です。団体を解約されると、その時点で団体全体が使用できなくなり、元に戻すことができません。（個人の退会ではございません。）また残りのご利用期間に対し、ご返金などの対応はできかねます。本当によろしいですか？"
      )
      if (!isConfirmed) {
        return
      }
    }

    navigation.navigate(Screens.DeleteOrganization, { id: organizationId, name: organizationDetail.organizationName })
  }, [navigation, organizationDetail, organizationId, allowShowOrder, orderDetail.orderStatus])

  const goToReportOrganization = useCallback(() => {
    if (organizationDetail == null) {
      return
    }
    navigation.navigate(Screens.ReportOrganization, { id: organizationId, name: organizationDetail.organizationName })
  }, [navigation, organizationDetail, organizationId])

  const goBack = useCallback(() => {
    navigation.navigate(Screens.Root, {
      screen: RootTabs.OrganizationManagerTab,
      params: {
        screen: OrganizationManagerScreens.OrganizationManager,
      },
    })
  }, [navigation])

  const openPaidVerDescription = useCallback(async () => {
    await WebBrowser.openBrowserAsync(helpPaidURL)
  }, [])

  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationThumbnailURL = useMemo(() => selectedOrganization?.uriImage, [selectedOrganization])
  const { value: hasBadge } = useAsyncSelector(hasMatchingNoticeSelector(organizationId))

  const ApplicantAuthorityButton = useMemo(
    () => (
      <Button
        style={[styles.quitButton]}
        onPress={() => {
          if (isLoadingOrganizationDetail) return
          navigation.navigate(Screens.CreateOrEditGroupStack, {
            params: { organizationId, memberId: organizationDetail?.profileInOrganization.id ?? "" },
            screen: "ApplicantAuthorityTransfer",
          })
        }}
      >
        <Text>申込者権限を譲渡する</Text>
      </Button>
    ),
    [navigation, organizationDetail, organizationId, isLoadingOrganizationDetail]
  )

  const PaidRegisterButton = useMemo(
    () => (
      <Button
        style={[styles.quitButton, { width: 139 }]}
        onPress={() => {
          if (isLoadingOrganizationDetail) return
          setApplicantId(organizationDetail?.profileInOrganization.id ?? "")
          navigation.navigate(Screens.registerApplicantInformation, {
            organizationId: organizationDetail?.id ?? "",
            mode: "init",
          })
        }}
      >
        <Text style={{ fontWeight: "bold", color: Colors.orange }}>有料版申込</Text>
      </Button>
    ),
    [navigation, organizationDetail, isLoadingOrganizationDetail, setApplicantId]
  )

  const PaidDetailButton = useMemo(
    () => (
      <Button
        style={[styles.quitButton, { width: 139 }]}
        onPress={() => {
          if (isLoadingOrganizationDetail) return
          setApplicantId(organizationDetail?.profileInOrganization.id ?? "")
          navigation.navigate(Screens.Root, {
            screen: RootTabs.OrganizationManagerTab,
            params: {
              screen: OrganizationManagerScreens.OrderDetail,
              params: {
                teamId: organizationId,
                teamMembers: organizationDetail?.memberNumber ?? 0,
                accountId: organizationDetail?.profileInOrganization.id ?? "",
              },
            },
          })
        }}
      >
        <Text>団体有料版詳細</Text>
      </Button>
    ),
    [navigation, organizationDetail, organizationId, isLoadingOrganizationDetail, setApplicantId]
  )

  const updateTeamMemberGradeButton = useMemo(
    () => (
      <Button
        style={[styles.singleButtonContainer, styles.addGroupButtonWrapper]}
        onPress={() => {
          if (isLoadingOrganizationDetail) return
          navigation.navigate(Screens.Root, {
            screen: RootTabs.OrganizationManagerTab,
            params: {
              screen: OrganizationManagerScreens.OrganizationMemberGrade,
              params: { organizationId },
            },
          })
        }}
      >
        <Text style={{ fontWeight: "bold", color: Colors.orange }}>一斉更新</Text>
      </Button>
    ),
    [navigation, organizationId, isLoadingOrganizationDetail]
  )

  return (
    <MainLayout>
      <View style={[styles.container]}>
        <View style={[styles.headerButtonContainer, { paddingTop: insets.top + 8 }]}>
          <Button style={styles.headerBackButton} onPress={goBack}>
            <BackIcon fill={Colors.white3} />
          </Button>
        </View>
        {organizationDetail != null ? (
          <ScrollView
            style={styles.main}
            refreshControl={<CustomRefreshControl refreshing={isLoadingOrganizationDetail} onRefresh={refresh} />}
          >
            <Image
              style={styles.headerImage}
              source={
                organizationImageUrl
                  ? { uri: organizationImageUrl }
                  : organizationThumbnailURL
                  ? { uri: organizationThumbnailURL }
                  : defaultTeam
              }
              resizeMode="cover"
              onError={refreshOrganizationImageUrl}
            />

            <View style={[styles.topContainer, { paddingTop: insets.top }]}>
              {showStar && (
                <View style={styles.starImageWrapper}>
                  <Image source={require("src/assets/images/startIcon.png")} style={styles.starImage} />
                </View>
              )}
              <View style={[styles.overviewContainer, { marginTop: !showStar ? 96 : 0 }]}>
                <Text style={styles.overviewLabel}>{organizationDetail.organizationName}</Text>
                <View style={styles.overviewDescriptionContainer}>
                  <View style={styles.overviewSeparateContainer}>
                    <View style={styles.overviewSeparateLine} />
                    <View style={styles.overviewSeparateIconWrapper}>
                      <DoubleQuoteStart />
                    </View>
                    <View style={styles.overviewSeparateLine} />
                  </View>
                  <Text style={styles.overviewDescriptionLabel}>{organizationDetail.profile}</Text>
                  <View style={styles.overviewSeparateContainer}>
                    <View style={styles.overviewSeparateLine} />
                  </View>
                </View>
              </View>
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                {matchableFlg && allowTeamMatching && !isLargeScreen && (
                  <MatchingButton onPress={goToMatching} showBadge={hasBadge} />
                )}
                <View
                  style={[
                    styles.parallelButtonContainer,
                    !allowEditOrganizationDetail || !allowViewMembers ? { justifyContent: "center" } : {},
                    isLargeScreen ? { width: 380 } : { width: "100%" },
                  ]}
                >
                  {allowEditOrganizationDetail ? (
                    <IconButton
                      buttonType={ButtonType.Primary}
                      IconComponent={<EditIcon />}
                      title="団体設定"
                      onPress={goToEditOrganizationDetail}
                    />
                  ) : null}
                  {allowViewMembers ? (
                    <IconButton
                      buttonType={ButtonType.Primary}
                      IconComponent={<MemberIcon />}
                      title={`メンバー一覧（${organizationDetail.memberNumber}）`}
                      onPress={goToOrganizationMember}
                    />
                  ) : null}
                </View>
              </View>
              <Text style={styles.annotationLabel}>
                {organizationDetail.isMembersPublic
                  ? "この団体の名簿は団体のメンバーに公開されています"
                  : "この団体の名簿は団体のメンバーに公開されていません"}
              </Text>
            </View>
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <View style={isLargeScreen ? { width: 600 } : { width: "100%" }}>
                {allowViewPendingInvitations ? (
                  organizationDetail.pendingInvitationsCount > 0 ? (
                    <View style={styles.invitations}>
                      <Button style={styles.invitationsButton} onPress={gotoPendingInvitations}>
                        <View style={styles.invitationLeft}>
                          <ItemLabel label="承認待ちのメンバー" />
                          <Text style={styles.invitationLabel}>
                            承認待ちのメンバーが{organizationDetail.pendingInvitationsCount}人います
                          </Text>
                        </View>
                        <View style={styles.invitationRight}>
                          <ChevronRightIcon />
                        </View>
                      </Button>
                    </View>
                  ) : (
                    <View style={styles.invitations}>
                      <View style={styles.invitationLeft}>
                        <ItemLabel label="承認待ちのメンバー" />
                        <Text style={styles.noRecordText}>承認待ちのメンバーはいません</Text>
                      </View>
                    </View>
                  )
                ) : null}
                {allowCreateGroup || listGroup.length > 0 ? (
                  <View style={styles.sectionContainer}>
                    <View style={styles.itemLabelWrapper}>
                      <ItemLabel
                        label="グループ"
                        RightComponent={
                          listGroup.length > 0 ? (
                            <Button onPress={goToOrganizationGroup}>
                              <Text style={styles.textButton}>グループ一覧</Text>
                            </Button>
                          ) : undefined
                        }
                      />
                      {listGroup.length === 0 ? <Text style={styles.noRecordText}>グループはありません</Text> : null}
                    </View>
                    {isLoadingOrganizationDetail ? (
                      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                        <LoadingIndicator />
                      </View>
                    ) : (
                      <>
                        {(showAllGroups ? listGroup : listGroup.slice(0, defaultShowGroupsCount)).map((group) => (
                          <Button
                            style={styles.rowButtonContainer}
                            key={group.id}
                            onPress={() => goToEditGroupSettingsInOrganization(group.id)}
                          >
                            <Text style={styles.rowButtonText}>{group.name}</Text>
                            <ChevronRightIcon />
                          </Button>
                        ))}
                      </>
                    )}
                    {!showAllGroups && listGroup.length > defaultShowGroupsCount ? (
                      <View style={styles.showMoreTextContainer}>
                        <Text style={styles.showMoreText} onPress={() => setShowAllGroups(true)}>
                          続きを見る
                        </Text>
                      </View>
                    ) : null}
                    {allowCreateGroup ? (
                      <View style={[styles.singleButtonContainer, styles.addGroupButtonWrapper]}>
                        <IconButton
                          buttonType={ButtonType.Primary}
                          IconComponent={<PlusIcon fillColor={Colors.orange} />}
                          title="グループ追加"
                          onPress={goToAddGroupInOrganization}
                        />
                      </View>
                    ) : null}
                  </View>
                ) : null}
                <View style={styles.sectionContainer}>
                  <View style={styles.itemLabelWrapper}>
                    <ItemLabel label="団体内個人設定" />
                  </View>
                  <View style={styles.profileContainer}>
                    <Image
                      style={styles.profileImage}
                      source={myProfileImageUrl ? { uri: myProfileImageUrl } : defaultThumbnail}
                      onError={refreshMyProfileImageUrl}
                    />
                    <View style={styles.profileInfoContainer}>
                      <Text style={styles.profileSubLabel}>表示されるニックネーム</Text>
                      <Text style={styles.profileName}>{organizationDetail.profileInOrganization.name}</Text>
                      <View style={styles.changeQuitButtonContainer}>
                        <Button onPress={gotoEditMyProfile} style={styles.profileChangeButton}>
                          <EditIcon />
                          <Text style={styles.profileChangeLabel}>変更</Text>
                        </Button>
                        <Button onPress={quit} disabled={isQuitting} style={styles.quitButton}>
                          <RemoveIcon size={10} />
                          <Text style={styles.quitLabel}>団体から脱退</Text>
                        </Button>
                      </View>
                    </View>
                  </View>
                </View>
                {((Platform.OS === "web" && allowCreateOrder) || allowShowOrder || allowTransferApplicant) && (
                  <View style={styles.sectionContainer}>
                    <View style={styles.itemLabelWrapper}>
                      <ItemLabel label="ご契約状況" />
                    </View>
                    {permissionLoading ? (
                      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                        <LoadingIndicator />
                      </View>
                    ) : (
                      <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: 35 }}>
                        <Text style={paidStatusStype}>{paidStatusText}</Text>
                        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                          {Platform.OS === "web" && paidFunctionEnabled && allowCreateOrder && PaidRegisterButton}
                          {allowShowOrder && paidFunctionEnabled && PaidDetailButton}
                          {isLargeScreen && paidFunctionEnabled && allowTransferApplicant && ApplicantAuthorityButton}
                        </View>
                      </View>
                    )}
                    {!permissionLoading && !isLargeScreen && (
                      <View style={{ flexDirection: "row", justifyContent: "flex-end", marginTop: 10 }}>
                        {allowTransferApplicant && paidFunctionEnabled && ApplicantAuthorityButton}
                      </View>
                    )}
                    <View style={styles.popUpNoticeText}>
                      <View style={[{ display: "flex", flexDirection: "row" }]}>
                        <View style={{ flexDirection: "row" }}>
                          <Text style={[styles.contentLink, { paddingRight: 3 }]}>※</Text>
                          <Text style={[styles.contentLink]}>団体有料版についての詳細は</Text>
                        </View>
                        <Text onPress={openPaidVerDescription}>
                          <Text style={styles.linkText}>こちら</Text>
                        </Text>
                      </View>
                    </View>
                  </View>
                )}
                {allowEditOrganizationDetail && organizationDetail.customQuestions?.includes(TeamCustomQuestion.grade) ? (
                  <View style={styles.sectionContainer}>
                    <View style={styles.itemLabelWrapper}>
                      <ItemLabel label="学年の一斉更新" />
                      {permissionLoading || isLoadingOrganizationDetail ? (
                        <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                          <LoadingIndicator />
                        </View>
                      ) : (
                        <>
                          {!organizationDetail.lastGradeUpdatedAt ? (
                            <Text style={styles.noRecordText}>学年の一斉更新履歴がありません。</Text>
                          ) : (
                            <Text style={styles.lastGradeUpdatedAt}>
                              学年の一斉更新が最後に行われたのは、{`\n`}
                              <Text style={styles.lastGradeUpdatedAtText}>{organizationDetail.lastGradeUpdatedAt}</Text>です。
                            </Text>
                          )}
                          <View style={[styles.singleButtonContainer, styles.updateGradeButtonWrapper]}>
                            <IconButton
                              buttonType={ButtonType.Primary}
                              IconComponent={<></>}
                              title="一斉更新"
                              onPress={() => {
                                if (isLoadingOrganizationDetail) return
                                navigation.navigate(Screens.Root, {
                                  screen: RootTabs.OrganizationManagerTab,
                                  params: {
                                    screen: OrganizationManagerScreens.OrganizationMemberGrade,
                                    params: { organizationId },
                                  },
                                })
                              }}
                            />
                          </View>
                        </>
                      )}
                    </View>
                  </View>
                ) : null}
                <View style={{ justifyContent: "center", alignItems: "center" }}>
                  <View style={isLargeScreen ? { width: 390 } : { width: "100%" }}>
                    <View style={[styles.sectionContainer, isLargeScreen ? { borderBottomWidth: 0 } : {}]}>
                      <View style={allowDeleteTeam ? styles.parallelButtonContainer : styles.singleButtonContainer}>
                        {allowDeleteTeam ? (
                          <View style={styles.singleButtonContainer}>
                            <IconButton
                              buttonType={ButtonType.Secondary}
                              IconComponent={<RemoveIcon />}
                              title="団体を解約"
                              onPress={goToDeleteOrganization}
                            />
                          </View>
                        ) : null}
                        <View style={styles.singleButtonContainer}>
                          <IconButton
                            buttonType={ButtonType.Secondary}
                            IconComponent={<></>}
                            title="通報"
                            onPress={goToReportOrganization}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        ) : (
          <View style={styles.loading}>
            <LoadingIndicator />
          </View>
        )}
        {(allowInviteMember || allowCreateContact) && (
          <DoubleButtonFooter
            leftButtonTitle="メンバー招待"
            rightButtonTitle="連絡作成"
            LeftButtonIcon={<JoinIcon size={24} />}
            RightButtonIcon={<CreateContactIcon size={28} />}
            onLeftButtonPress={goToInvitationMember}
            onRightButtonPress={goToCreateContact}
            hideLeft={!allowInviteMember}
            hideRight={!allowCreateContact}
            isLargeScreen={isLargeScreen}
          />
        )}
      </View>
    </MainLayout>
  )
})

export const options: ScreenOptions = {
  headerShown: false,
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  main: {
    flex: 1,
    backgroundColor: "#faf8f5",
  },
  sectionContainer: {
    borderBottomWidth: 1,
    borderColor: Colors.white2,
    padding: 16,
  },
  itemLabelWrapper: {
    marginBottom: 6,
  },
  textButton: {
    textAlign: "right",
    fontSize: 14,
    fontWeight: "bold",
    color: Colors.orange,
  },

  topContainer: {
    borderBottomWidth: 0.5,
    borderColor: "#d4d4d4",
    paddingHorizontal: 16,
    paddingBottom: 20,
  },

  headerImage: {
    width: "100%",
    height: 200,
    position: "absolute",
  },
  headerButtonContainer: {
    position: "absolute",
    left: 0,
    zIndex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 16,
  },
  headerBackButton: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: "rgba(32,32,32,0.3)",
    justifyContent: "center",
    alignItems: "center",
  },
  overviewContainer: {
    marginTop: 0,
    paddingTop: 16,
    paddingBottom: 26,
    paddingHorizontal: 26,
    backgroundColor: Colors.orange,
    alignItems: "center",
    borderRadius: 16,
  },
  overviewSubLabel: {
    fontSize: 12,
    marginTop: 10,
    textAlign: "center",
    color: Colors.white3,
  },
  overviewLabel: {
    fontSize: 19,
    fontWeight: "bold",
    marginTop: 9,
    color: Colors.white3,
  },
  overviewSeparateContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  overviewSeparateLine: {
    height: 1,
    flex: 1,
    borderTopWidth: 0.5,
    borderColor: Colors.white3,
    opacity: 0.3,
  },
  overviewSeparateIconWrapper: {
    marginHorizontal: 15,
  },
  overviewDescriptionLabel: {
    color: Colors.white3,
    fontSize: 13,
    fontWeight: "bold",
    lineHeight: 20,
    textAlign: "center",
    marginVertical: 18,
  },
  overviewDescriptionContainer: {
    marginTop: 17,
    width: "100%",
  },
  parallelButtonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 16,
  },
  singleButtonContainer: {
    alignItems: "center",
  },
  annotationLabel: {
    fontSize: 12,
    color: Colors.warmGrey,
    textAlign: "center",
    marginTop: 20,
  },
  invitationRowSectionContainer: {
    borderBottomWidth: 0.5,
    borderColor: Colors.white2,
    paddingVertical: 16,
  },
  invitationRowTopContainer: {
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  invitationRowButtonContainer: {
    backgroundColor: Colors.white3,
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  invitationRowButtonWrapper: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  invitationRowTextWrapper: {
    alignItems: "flex-end",
    justifyContent: "center",
  },
  invitationRowText: {
    fontSize: 12,
    color: Colors.warmGrey,
  },
  invitationRowTextButton: {
    marginTop: 5,
    fontSize: 11,
    color: Colors.orange,
  },
  invitationRowButtonImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  invitationRowButtonTitle: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: "bold",
    marginLeft: 14,
  },
  invitationRowApproveButtonContainer: {
    paddingVertical: 8,
    paddingHorizontal: 17,
    borderRadius: 22,
    backgroundColor: Colors.orange,
  },
  invitationRowApproveButtonText: {
    fontSize: 14,
    fontWeight: "500",
    color: Colors.white3,
  },
  invitationRowRejectButtonContainer: {
    paddingVertical: 8,
    paddingHorizontal: 17,
    borderRadius: 22,
    borderWidth: 1,
    borderColor: Colors.silver,
    backgroundColor: Colors.white7,
    marginLeft: 8,
  },
  invitationRowRejectButtonText: {
    fontSize: 14,
    fontWeight: "500",
    color: Colors.greyshBrown,
  },
  rowButtonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: Colors.white3,
    borderRadius: 16,
    paddingVertical: 18,
    paddingHorizontal: 25,
    marginTop: 8,
    shadowColor: Colors.lightTan,
    shadowOpacity: 0.38,
    shadowRadius: 8,
    elevation: 3,
  },
  rowButtonText: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: "bold",
  },
  showMoreTextContainer: {
    flexDirection: "row",
    justifyContent: "center",
    paddingVertical: 16,
  },
  showMoreText: {
    fontSize: 14,
    padding: 8,
    fontWeight: "bold",
    color: Colors.orange,
  },
  fontColorCement: {
    color: Colors.cement,
  },
  addGroupButtonWrapper: {
    marginTop: 16,
  },
  profileContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: 8,
  },
  profileImage: {
    width: 76,
    height: 76,
    borderRadius: 38,
  },
  profileInfoContainer: {
    marginLeft: 28,
    display: "flex",
    flex: 1,
  },
  profileSubLabel: {
    fontSize: 12,
    color: Colors.warmGrey,
  },
  profileName: {
    marginTop: 4,
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    maxWidth: "100%",
  },
  changeQuitButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
  },
  profileChangeLabel: {
    color: Colors.orange,
    fontSize: 14,
    fontWeight: "bold",
    paddingLeft: 8,
  },
  profileChangeButton: {
    color: Colors.orange,
    fontSize: 13,
    fontWeight: "500",
    borderRadius: 4,
    backgroundColor: "#efeae3",
    paddingVertical: 8,
    paddingHorizontal: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  quitButton: {
    borderRadius: 4,
    backgroundColor: "#efeae3",
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginLeft: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  quitLabel: {
    color: Colors.greyshBrown,
    fontWeight: "bold",
    fontSize: 13,
    marginLeft: 8,
  },
  invitations: {
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  invitationsButton: {
    flexDirection: "row",
  },
  invitationLeft: {
    flex: 1,
  },
  invitationRight: {
    justifyContent: "center",
  },
  noRecordText: {
    marginTop: 8,
    marginLeft: 16,
    color: Colors.warmGrey,
    fontSize: 14,
  },
  invitationLabel: {
    marginTop: 8,
    marginLeft: 16,
    color: Colors.orange,
    fontWeight: "500",
    fontSize: 14,
  },
  loading: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  starImageWrapper: {
    marginTop: 56,
    top: 20,
    zIndex: 1,
    justifyContent: "center",
    flexDirection: "row",
  },
  starImage: {
    width: 40,
    height: 40,
    objectFit: "cover",
  },
  contentLink: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "left",
  },
  linkText: {
    color: Colors.orange,
    textDecorationLine: "underline",
  },
  popUpNoticeText: {
    paddingTop: 20,
  },
  lastGradeUpdatedAt: {
    marginTop: 8,
    marginLeft: 16,
    fontSize: 14,
  },
  updateGradeButtonWrapper: {
    marginTop: 16,
  },
  lastGradeUpdatedAtText: {
    color: Colors.red,
    fontWeight: "bold",
  },
})
