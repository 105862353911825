import { remoteConfigWrap } from "src/tags/remote-config"
import React, { memo, useCallback, useState } from "react"
import { Button } from "src/components/parts/buttons/Button"
import { Dimensions, Image, Linking } from "react-native"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { RemoteConfigBanners } from "src/constants/RemoteConfigBanners"
import { useFocusEffect } from "@react-navigation/native"

type Props = {
  bannerKey: RemoteConfigBanners
  isShowByPaidTeam: boolean
}

export const RemoteConfigBanner = memo<Props>(({ bannerKey, isShowByPaidTeam }) => {
  const [imageUrl, setImageUrl] = useState("")
  const [destinationUrl, setDestinationUrl] = useState("")
  const isLargeScreen = useCheckPCScreen()
  const [aspectRatio, setAspectRatio] = useState(1)
  const screenWidth = Dimensions.get("window").width

  useFocusEffect(
    useCallback(() => {
      remoteConfigWrap.fetchAndActivate().then(async () => {
        const remoteValue = remoteConfigWrap.getJson(bannerKey)
        if (remoteValue.img !== "") {
          const { width, height } = await getImageSize(remoteValue.img)
          setAspectRatio(width / height)
        }
        setImageUrl(remoteValue.img)
        setDestinationUrl(remoteValue.url)
      })
    }, [bannerKey])
  )

  const getImageSize = (uri: string): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      Image.getSize(
        uri,
        (width, height) => resolve({ width, height }),
        (error) => reject(error)
      )
    })
  }

  return (
    <>
      {!isLargeScreen && isShowByPaidTeam && imageUrl && destinationUrl ? (
        <Button
          onPress={() => Linking.openURL(destinationUrl)}
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            source={{ uri: imageUrl }}
            style={{
              width: screenWidth,
              height: screenWidth / aspectRatio,
              resizeMode: "contain",
            }}
          />
        </Button>
      ) : null}
    </>
  )
})
