import { memo, useCallback, useMemo } from "react"
import { ScrollView, StyleSheet, Text, View } from "react-native"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { openConfigureSpam } from "src/utils/links/openConfigureSpam"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { UnorderedList } from "src/components/parts/UnorderedList"
import { ExpiryBadge } from "src/components/parts/authorization/ExpiryBadge"
import { validateEmail, containsUppercase } from "src/utils/validateEmail"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

type Props = {
  label: string
  email: string
  onEmailChange: (next: string) => void
  sendAuthCode: () => void
  isDisabled: boolean
  isEditable?: boolean
  gotoLoginPhoneNumber?: () => void
}

export const EnterEmail = memo<Props>(
  ({ label, email, onEmailChange, sendAuthCode, isDisabled, isEditable = true, gotoLoginPhoneNumber }) => {
    const isValid = useMemo(() => validateEmail(email), [email])
    const isContainsUpperCase = useMemo(() => containsUppercase(email), [email])
    const caveats = useMemo(() => (gotoLoginPhoneNumber != null ? loginCaveats : signupCaveats), [gotoLoginPhoneNumber])
    const gotoAlternativeMethod = useCallback(() => gotoLoginPhoneNumber?.(), [gotoLoginPhoneNumber])
    const isLargeScreen = useCheckPCScreen()
    return (
      <ScrollView style={{ backgroundColor: Colors.beige }}>
        <View style={styles.container}>
          <View style={isLargeScreen ? styles.containerPC : { width: "100%" }}>
            {gotoLoginPhoneNumber != null ? null : (
              <View style={[styles.caveats, styles.separator]}>
                <Text style={styles.note}>認証コードや各種通知を受信するためのメールアドレスを入力してください。</Text>
              </View>
            )}
            <View style={[styles.form, isLargeScreen ? null : styles.separator, isLargeScreen ? { width: 385 } : null]}>
              <View style={styles.itemLabelWrapper}>
                <ItemLabel
                  size={15}
                  label={label}
                  RightComponent={<InputLengthCounter text={email} maxLength={255} unit={"字以内"} />}
                />
              </View>
              <View style={styles.inputContainer}>
                <CustomTextInput
                  value={email}
                  onChangeText={onEmailChange}
                  placeholder="入力してください"
                  placeholderTextColor={Colors.white6}
                  editable={isEditable}
                  onSubmitEditing={isValid ? sendAuthCode : undefined}
                  keyboardType="email-address"
                  autoCapitalize="none"
                  maxLength={255}
                />
              </View>
              {isContainsUpperCase && (
                <Text style={{ marginTop: 10, color: Colors.red }}>メールアドレスに大文字が含まれています</Text>
              )}
            </View>
            {isLargeScreen && <View style={[styles.separator, { width: 540 }]}></View>}
            <View style={[isLargeScreen ? { display: "flex", alignItems: "center" } : {}]}>
              <View style={[styles.actions, isLargeScreen ? styles.buttonsPC : {}]}>
                <TextButton
                  buttonType={ButtonType.Primary}
                  title="認証コードを送信"
                  onPress={sendAuthCode}
                  disabled={isDisabled || !isValid || isContainsUpperCase}
                  isLoading={!isEditable}
                />
              </View>
            </View>
            <View style={styles.badgeContainer}>
              <ExpiryBadge />
            </View>
            <View style={[styles.caveats, styles.separator, gotoLoginPhoneNumber != null ? null : { borderBottomWidth: 0 }]}>
              <UnorderedList items={caveats} />
            </View>
            {gotoLoginPhoneNumber != null ? (
              <View style={styles.separator}>
                <Text onPress={gotoAlternativeMethod} style={styles.alternative}>
                  携帯電話番号でログインする方はこちら
                </Text>
              </View>
            ) : null}
          </View>
        </View>
      </ScrollView>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.beige,
    alignItems: "center",
  },
  containerPC: {
    display: "flex",
    alignItems: "center",
    width: 600,
    maxWidth: 1020,
    marginLeft: "20%",
    marginRight: "20%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  inputContainer: {
    paddingTop: 10,
    // width: 350,
  },
  validationErrorMessage: {
    marginTop: 5,
  },
  form: {
    paddingBottom: 24,
    paddingHorizontal: 17,
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  oldRakuTitle: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    backgroundColor: Colors.redBold,
    borderRadius: 4,
    color: Colors.white,
    fontSize: 16,
    fontWeight: "300",
    marginBottom: 16,
    overflow: "hidden",
  },
  oldRakuSubTitle: {
    color: Colors.black2,
    fontSize: 18,
    fontWeight: "600",
    lineHeight: 25.2,
    fontFamily: "Hiragino Kaku Gothic Pro",
    marginBottom: 12,
    fontStyle: "normal",
  },
  actions: {
    padding: 24,
  },
  badgeContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  caveats: {
    padding: 24,
  },
  itemLabelWrapper: {
    marginTop: 20,
  },
  link: {
    color: Colors.orange,
  },
  alternative: {
    alignSelf: "center",
    marginVertical: 16,
    color: Colors.orange,
    fontWeight: "bold",
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
  warning: {
    color: Colors.red,
    fontWeight: "400",
    fontSize: 14,
  },
  note: {
    fontWeight: "400",
    fontSize: 14,
  },
})

const loginCaveats = [
  <Text>
    「@ra9plus.jp」からのメールが受け取れるよう設定をお願いします。設定方法は
    <Text onPress={openConfigureSpam} style={styles.link}>
      こちら
    </Text>
    。
  </Text>,
]

const signupCaveats = [
  <Text>
    入力いただいたメールアドレス宛に、認証コードをお送りします。メールアドレスはお客様の認証目的のために利用します。お手続きされなかった場合、入力されたメールアドレスは31日以内に削除されます。
  </Text>,
  ...loginCaveats,
  <Text>ソフトバンクのiPhoneをご利用の場合は、「softbank.ne.jp(MMS)」以外のEメールアドレスにてご登録をお願いいたします。</Text>,
]
