import { ValueOf } from "src/types.d"

export const OrganizationManagerScreens = Object.freeze({
  OrganizationManager: "OrganizationManager",
  OrganizationDetail: "OrganizationDetail",
  OrganizationMembers: "OrganizationMembers",
  OrganizationMemberGrade: "OrganizationMemberGrade",
  OrganizationGroups: "OrganizationGroups",
  CompletedApplyPaid: "CompletedApplyPaid",
  OrderDetail: "OrderDetail",
} as const)

export type OrganizationManagerScreens = ValueOf<typeof OrganizationManagerScreens>
