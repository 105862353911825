import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react"
import { Image, ImageStyle, Linking, Modal, StyleSheet, Text, View } from "react-native"
import { Colors } from "src/constants/Colors"
import { Button } from "src/components/parts/buttons/Button"
import { CreateOrganizationIcon } from "src/components/parts/icons/CreateOrganizationIcon"
import { JoinIcon } from "src/components/parts/icons/JoinIcon"
import { useNavigation } from "@react-navigation/native"
import { Screens } from "src/constants/Screens"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { CustomAlert } from "src/utils/CustomAlert"
import { Analytics } from "src/tags/analytics/Analytics"
import { myFullOrganizationsSelector } from "src/recoil/selectors/organization/myOrganizationsSelector"
import { MaxCount } from "src/constants/MaxCount"
import { faqURL } from "../constants/links/guide"

export const MainLayout: FC<PropsWithChildren> = ({ children }) => {
  const isPCScreen = useCheckPCScreen()
  const navigation = useNavigation()
  const { value: organizations } = useAsyncSelector(myFullOrganizationsSelector)

  const gotoJoinOrganization = useCallback(async () => {
    await Analytics.sendJoinRequest()
    if ((organizations?.length || 0) >= MaxCount.Team) {
      CustomAlert.alert(
        "エラー",
        `所属団体数が上限を超えるため、参加できません。\n※1人の方が所属できる団体数は最大${MaxCount.Team}団体です。`
      )
      return
    }
    navigation.navigate(Screens.JoinOrganization)
  }, [navigation, organizations])

  const gotoCreateOrganization = useCallback(() => {
    if ((organizations?.length || 0) >= MaxCount.Team) {
      CustomAlert.alert(
        "エラー",
        `所属団体数が上限を超えるため、作成できません。\n※1人の方が所属できる団体数は最大${MaxCount.Team}団体です。`
      )
      return
    }
    navigation.navigate(Screens.CreateOrganizationStack)
  }, [navigation, organizations])

  if (isPCScreen) {
    return (
      <View style={styles.container}>
        <View style={styles.mainContent}>{children}</View>
        <View style={styles.rightSideBar}>
          <Button style={[styles.button, { backgroundColor: Colors.leaf }]} onPress={gotoJoinOrganization}>
            <JoinIcon size={24} />
            <Text style={styles.buttonLabel}>団体に参加</Text>
          </Button>
          <Button style={[styles.button, { backgroundColor: Colors.orange }]} onPress={gotoCreateOrganization}>
            <CreateOrganizationIcon size={33} />
            <Text style={styles.buttonLabel}>団体を作成</Text>
          </Button>
          <View>
            <Button onPress={() => Linking.openURL("https://www.ra9plus.jp/guide")}>
              <Image style={styles.svg as ImageStyle} source={require("src/assets/images/userGuide.svg")} />
            </Button>
          </View>
          <View>
            <Button onPress={() => Linking.openURL(faqURL)}>
              <Image style={styles.svg as ImageStyle} source={require("src/assets/images/banner_faq.png")} />
            </Button>
          </View>
        </View>
      </View>
    )
  } else {
    return <>{children}</>
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "rgb(250, 248, 245)",
  },
  mainContent: {
    minWidth: 600,
    // @ts-ignore
    width: "calc(100% - 300px)",
    // @ts-ignore
    height: "100vh",
    flexDirection: "column",
  },
  rightSideBar: {
    width: 300,
    padding: 16,
    flexDirection: "column",
    gap: 10,
    backgroundColor: Colors.sidebarBg,

    // @ts-ignore
    minHeight: "100vh",
  },
  button: {
    flexDirection: "row",
    height: 44,
    justifyContent: "center",
    gap: 12,
    alignItems: "center",
    borderRadius: 6,
  },
  buttonLabel: {
    color: Colors.white,
    fontSize: 16,
    fontWeight: "bold",
  },
  migrateButtonContainer: {
    flexDirection: "column",
    alignItems: "center",
    paddingVertical: 10,
    gap: 12,
    backgroundColor: Colors.orange,
    borderRadius: 10,
  },
  migrationButtonLabel: {
    color: Colors.white,
    fontSize: 13,
    fontWeight: "bold",
  },
  aboutMigrationContainer: {
    flexDirection: "row",
    paddingVertical: 4,
    marginBottom: 12,
    alignItems: "center",
  },
  aboutMigrationText: {
    color: Colors.greyshBrown,
    textDecorationLine: "underline",
    marginLeft: 8,
  },
  svg: {
    width: 268,
    height: 90,
  },
  modalWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.37)",
    padding: 22,
  },
  migrationHelpContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  listTeamMigrateButtonContainer: {
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 17,
    backgroundColor: Colors.leaf,
    borderRadius: 10,
    width: "100%",
  },
  migrateButtonText: {
    color: Colors.white3,
    fontSize: 15,
    fontWeight: "700",
    lineHeight: 20,
    textAlign: "center",
  },
  contentLink: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "left",
  },
  linkText: {
    color: Colors.orange,
    textDecorationLine: "underline",
  },
  popUpNoticeText: {
    paddingHorizontal: 16,
    paddingTop: 10,
  },
  popUpNotice: {
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  doubleButtonHeader: {
    flexDirection: "row",
    width: "100%",
  },
  buttonContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexDirection: "row",
    width: "43%",
  },
  centerRow: {
    alignItems: "center",
  },
  migrateContainer: {
    borderRadius: 6,
    backgroundColor: Colors.white3,
  },
})
